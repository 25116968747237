module services {
    export module applicationcore {
        export class tokenService implements interfaces.applicationcore.ITokenService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getNewToken(): ng.resource.IResourceClass<ng.resource.IResource<{ value: string }>> {
                return this.$resource<ng.resource.IResource<{ value: string }>>(this.ENV.DSP_REQUEST_URL_USER + "GetNewToken",
                    {
                    }, {
                        get: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    }
                );
            }
            
        }
    }
    angular.module("app").service("tokenService", services.applicationcore.tokenService);
}